<template>
	<home-permissions />
</template>
<script>
import HomePermissions from '@/components/permissions/home/index.vue';

export default {
	name: 'HomePermissionsPage',
	components: { HomePermissions },	
};
</script>
